/**
 * Retry Interceptor
 *
 * This service contains an interceptor that will catch CSRF token invalid errors and
 * 1. Refresh the token
 * 2. Retry the request with the new token
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { AppService } from '../../app.service';
import { AuthApiService } from './auth.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  /**
   * Retry Interceptor
   * @param appService - Global application service
   * @param authApiService - Auth API service
   */
  constructor(private readonly appService: AppService, private readonly authApiService: AuthApiService) {}

  /**
   * Intercept HTTP responses
   * @param request - HTTP Request
   * @param next - Next HTTP Handler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 400 && error.error === 'Invalid CSRF token') {
          return from(this.refreshCsrfToken()).pipe(switchMap(() => next.handle(request)));
        }
        return throwError(() => error);
      }),
    );
  }

  /**
   * Refresh CSRF token
   */
  async refreshCsrfToken(): Promise<void> {
    try {
      const user = await this.authApiService.getSession();
      this.appService.setAccount(user);
    } catch (error) {}
  }
}
