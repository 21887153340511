/**
 * XSRF Interceptor
 *
 * This service contains an interceptor that adds an XSRF header to all
 * supported DTU API requests
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { XSRFService } from './xsrf.service';

/**
 * Class representing the XSRF Interceptor service
 */
@Injectable()
export class XSRFInterceptor implements HttpInterceptor {
  /**
   * XSRF Interceptor
   * @param xsrfService - XSRF Service
   */
  constructor(private readonly xsrfService: XSRFService) {}

  /**
   * Intercept HTTP requests and add the XSRF header when appropriate
   * @param request - HTTP request
   * @param next - Next function
   * @returns The updated request
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add XSRF header to DTU API requests
    if (this.xsrfService.xsrfToken && request.url.startsWith(environment.api) && !['HEAD', 'GET'].includes(request.method)) {
      request = request.clone({ headers: request.headers.set('x-csrf-token', this.xsrfService.xsrfToken) });
    }

    // Return request
    return next.handle(request);
  }
}
