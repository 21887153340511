export const enum FlagNames {
  // UI
  DisabledAdminSections = 'dtu-ui.disabled-admin-sections',
  EnableDynatraceTabInOnDemandLibrary = 'dtu-ui.enable-dynatrace-tab-in-on-demand-library',
  EnableMaintenanceScreen = 'dtu-ui.enable-maintenance-screen',
  GlobalBannerConfig = 'dtu-ui.global-banner-config',
  EnableContentExplorerUi = 'dtu-ui.enable-content-explorer-ui',
  EnableBlockEmployeeExamScheduling = 'dtu-ui.enable-block-employee-exam-scheduling',
  Gen3AccountUuid = 'dtu-ui.gen-3-account-uuid',
  Gen3ClusterUuid = 'dtu-ui.gen-3-cluster-uuid',
  EnableAutoGrading = 'dtu-ui.enable-auto-grading',
  EnableProctorU = 'dtu-ui.enable-proctoru-integration',
  EnableAetherConfiguration = 'dtu-ui.enable-aether-configuration',
  EnableAutoGraderFinalGradingExamIds = 'dtu-ui.enable-auto-grader-final-grading-exam-ids',

  // API
  // Global Rate Limiter
  EnableGlobalRateLimiter = 'dtu-api.enable-global-rate-limiter',
  GlobalRateLimiterWindowSize = 'dtu-api.global-rate-limiter-window-size',
  GlobalRateLimiterAdminRequestMax = 'dtu-api.global-rate-limiter-admin-request-maximum',
  GlobalRateLimiterPublicRequestMax = 'dtu-api.global-rate-limiter-public-request-maximum',
  // Activity Rate Limiter
  EnableActivityRateLimiter = 'dtu-api.enable-activity-rate-limiter',
  ActivityRateLimiterWindowSize = 'dtu-api.activity-rate-limiter-window-size',
  ActivityRateLimiterAdminRequestMax = 'dtu-api.activity-rate-limiter-admin-request-maximum',
  ActivityRateLimiterPublicRequestMax = 'dtu-api.activity-rate-limiter-public-request-maximum',
}
