/**
 * App Module
 *
 * This module handles all functionality for the application
 */

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MAT_LEGACY_TABS_CONFIG as MAT_TABS_CONFIG } from '@angular/material/legacy-tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@dynatrace/common-components';
import { QuillModule } from 'ngx-quill';
import { AdminSharedService } from './admin/shared/admin-shared.service';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { CoreModule } from './core/core.module';
import { MaintenanceGuard } from './maintenance/maintenance-guard.service';
import { AuthApiService } from './shared/api/auth.service';
import { RetryInterceptor } from './shared/api/retry-interceptor.service';
import { XSRFInterceptor } from './shared/api/xsrf-interceptor.service';
import { XSRFService } from './shared/api/xsrf.service';
import { AdminGuard } from './shared/auth/admin-guard.service';
import { AdminResolver } from './shared/auth/admin-resolve.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { EmployeeGuard } from './shared/auth/employee-guard.service';
import { OverviewGuard } from './shared/auth/overview-guard.service';
import { RedirectGuard } from './shared/auth/redirect-guard.service';

function initializeApp(appInitService: AppInitService) {
  return () => {
    return appInitService.initApp();
  };
}

/**
 * Class representing the App module
 */
@NgModule({
  declarations: [AppComponent],
  imports: [AppRoutingModule, BrowserAnimationsModule, BrowserModule, CoreModule, HttpClientModule, QuillModule.forRoot()],
  providers: [
    AdminGuard,
    AdminResolver,
    AdminSharedService,
    AppInitService,
    AppService,
    AuthApiService,
    AuthGuard,
    EmployeeGuard,
    MaintenanceGuard,
    OverviewGuard,
    RedirectGuard,
    XSRFService,
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XSRFInterceptor, multi: true },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { closeOnNavigation: true, hasBackdrop: true, panelClass: ['dtu-dialog', 'dtu-new-styles'], width: '600px' },
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
