import { Injectable } from '@angular/core';

/**
 * Class representing the XSRF service
 */
@Injectable()
export class XSRFService {
  _xsrfToken: string;

  get xsrfToken() {
    return this._xsrfToken;
  }

  set xsrfToken(newXsrfToken: string) {
    this._xsrfToken = newXsrfToken;
  }
}
