import { Values } from './type-utils';

export const TokenServices = {
  AccountManagementAutoGraderProd: 'accountManagementAutoGraderProd',
  AccountManagementAutomationStg: 'accountManagementAutomationStg',
  AccountManagementAutomationProd: 'accountManagementAutomationProd',
  AccountManagementMonitoringProd: 'accountManagementMonitoringProd',
  AdaAutomationStg: 'adaAutomationStg',
  AdaAutomationProd: 'adaAutomationProd',
  // Perform
  AccountManagementAutomationPerformS01Prod: 'accountManagementAutomationPerformS01Prod',
  AccountManagementAutomationPerformS02Prod: 'accountManagementAutomationPerformS02Prod',
  AccountManagementAutomationPerformS03Prod: 'accountManagementAutomationPerformS03Prod',
  AccountManagementAutomationPerformS04Prod: 'accountManagementAutomationPerformS04Prod',
  AccountManagementAutomationPerformS05Prod: 'accountManagementAutomationPerformS05Prod',
  AccountManagementAutomationPerformS06Prod: 'accountManagementAutomationPerformS06Prod',
  AccountManagementAutomationPerformS07Prod: 'accountManagementAutomationPerformS07Prod',
  AccountManagementAutomationPerformS08Prod: 'accountManagementAutomationPerformS08Prod',
  AccountManagementAutomationPerformS09Prod: 'accountManagementAutomationPerformS09Prod',
  AccountManagementAutomationPerformS10Prod: 'accountManagementAutomationPerformS10Prod',
  AccountManagementAutomationPerformS11Prod: 'accountManagementAutomationPerformS11Prod',
  AccountManagementAutomationPerformS12Prod: 'accountManagementAutomationPerformS12Prod',
  AccountManagementAutomationPerformS13Prod: 'accountManagementAutomationPerformS13Prod',
  AccountManagementAutomationPerformS14Prod: 'accountManagementAutomationPerformS14Prod',
  AccountManagementAutomationPerformS15Prod: 'accountManagementAutomationPerformS15Prod',
};
export type TokenServices = Values<typeof TokenServices>;

export enum TokenStage {
  Development = 'dev',
  Staging = 'stg',
  Production = 'prod',
}

export interface TokenCredentials {
  stage: TokenStage;
  clientId: string;
  clientSecret: string;
  scopes?: string;
  resource?: string;
}

export interface TokenInfoList {
  [service: TokenServices]: TokenInfo | null;
}

export interface TokenInfo {
  token: string;
  expiresAt: string | Date;
  expiresIn?: number;
}

export interface TokenInfoWithExpiration extends TokenInfo {
  expiresIn: number;
}
